import styled from 'styled-components'
import Link from '../../components/Link'
import { Box, Text, Flex } from 'rebass'
import { media, typeface, easeOutExpo, easeOutCirc, StyledLinks, borderHighlightColour, RouterRollOver } from '../../theme/'

export const ItemLink = styled(Link)`
  text-decoration: none;
  position: relative;
  z-index: 2;
  &:hover .highlight {
    color: ${RouterRollOver};
    cursor: pointer;
    transition: color 0.3s ${easeOutExpo};
  }
`
export const Title = styled(Text)`
  font-family: ${typeface.sans};
  font-weight: 700;
  color: white;
`
export const FlexWrapper = styled(Flex)`
  position: relative;
  z-index: 10;
`
export const Attribution = styled(Text)`
  font-weight: 700;
  font-style: italic;
  color: rgba(226, 222, 221, 1);
  letter-spacing: 0.05em;
`
export const Item = styled(Box)`
  z-index: 1;
  position: relative;
  ${media.LargeScreenUp`
    &:nth-child(4n+1):after {
      background: red;
      content: '';
      display: block;
      position: absolute;
      bottom: -60%;
      left: 0;
      transform: translateX(9vw);
      width: 4px;
      height: 65%;
      background: ${borderHighlightColour};
      z-index: -2;
    }
  `}
  ${media.GiantScreenUp`
    &:nth-child(4n+1):after {
      display: none;
    }
    &:nth-child(5n+1):after {
      background: red;
      content: '';
      display: block;
      position: absolute;
      bottom: -60%;
      left: 0;
      transform: translateX(6.5vw);
      width: 4px;
      height: 65%;
      background: ${borderHighlightColour};
      z-index: -2;
    }
  `}
`

export const Filters = styled(Box)`
  z-index: 1;
  position: relative;
  display: none;
  ${StyledLinks}
  ${media.LargeScreenUp`
    display: block;
  `}
`
export const Shadow = styled(Box)`
  position: relative;
  cursor: pointer;
  &:before {
    content: '';
    position: absolute;
    z-index: 0;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 93%;
    height: 90%;
    opacity: 0.35;
    box-shadow: 0 25px 25px 0px rgba(0, 0, 0, 0.52), 0 20px 20px 0 rgba(0, 0, 0, 0.48);
    transition: transform 1s ${easeOutCirc};
  }
  &:hover:before {
    will-change: transform;
    transform: translateX(-50%) scale(0.95);
  }
`
