import React, { Component } from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import { isWindowAvailable } from '../util'
import { black } from '../theme'
import Layout from '../components/Layout'
import Router from '../components/Router'
import Listing from '../components/Listing'
import Footer from '../components/Footer'
import Stripe from '../components/Stripe'
import PageTitle from '../components/PageTitle'
import { first } from 'lodash'

class ArtistListingTemplate extends Component {
  constructor (props) {
    super(props)
    const normalizedListing = props.data.allWordpressPage.edges.map((artist) => {
      const image = first(artist.node.acf.artist_images)
      const sizes = (image && image.localFile) ? image.localFile.childImageSharp.sizes : null
      return {
        title: artist.node.title,
        attribution: undefined,
        sizes: sizes,
        link: artist.node.link
      }
    })
    this.state = {
      artists: normalizedListing
    }
  }
  render () {
    const siteMetadata = this.props.data.site.siteMetadata
    const currentPage = this.props.data.wordpressPage
    const bodyStyle = (isWindowAvailable) ? { style: `background-color: ${black.mid};` } : null
    return (
      <Layout>
        { currentPage && currentPage.acf.seo_page_title &&
          <Helmet title={currentPage.acf.seo_page_title} bodyAttributes={bodyStyle} />
        }
        { currentPage && !currentPage.acf.seo_page_title &&
          <Helmet title={`${siteMetadata.title} - ${currentPage.title}`} bodyAttributes={bodyStyle} />
        }
        <Router
          items={first(this.props.data.allWordpressWpApiMenusMenusItems.edges).node.items}
        />
        <PageTitle title={currentPage.title} prefix />
        <Listing items={this.state.artists} link />
        <Stripe top />
        <Stripe filter />
        <Footer />
      </Layout>
    )
  }
}

export default ArtistListingTemplate

export const ArtistListingQuery = graphql`
  query artistListingPageQuery($id: String!) {
    allWordpressWpApiMenusMenusItems(
      filter: { name: { regex: "/Router/" } },
      sort: { fields: [name], order: DESC}
    ) {
      edges {
        node {
          id
          name
          items {
            title
            url
            type
          }
        }
      }
    }
    allWordpressPage(filter: {categories: {elemMatch: {name: {eq: "Artist"}}}}, sort: {fields: [title], order: ASC}) {
      edges {
        node {
          categories {
            name
          }
          title
          link
          acf {
            artist_images {
              localFile {
                childImageSharp {
                  sizes(
                    maxWidth: 800,
                    maxHeight: 800,
                    duotone: {
                      highlight: "#f00e2e",
                      shadow: "#192550",
                      opacity: 0
                    },
                  ) {
                      ...GatsbyImageSharpSizes_withWebp
                    }
                }
              }
            }
          }
        }
      }
    }
    
    wordpressPage(id: { eq: $id }) {
      title
      content
      date(formatString: "MMMM DD, YYYY")
      acf {
        seo_page_title
      }
    }
    
    site {
      id
      siteMetadata {
        title
      }
    }
  }
`
