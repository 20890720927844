import React from 'react'
import { Box } from 'rebass'
import { Item, Attribution, Title, Filters, ItemLink, FlexWrapper, Shadow } from './styles'
import Img from 'gatsby-image'
import assetDefault from '../../images/default-listing-image.svg'
const Listing = (props) => {
  return (
    <Box w={1 / 1} pb={[50, null, 0]}>
      <Filters ml={[0, null, 85]} pt={[20, null, 110]} px={['10%']}>
        <a href='#all' alt='filter: all' className='active'>All</a>
      </Filters>
      <FlexWrapper flexWrap='wrap' w={'100%'} px={'5%'} pt={[10, null, 55]} pb={[30, null, 150]}>
        {
          props.items.map((item, key) => {
            const cleanedTitle = item.title.replace('&#038;', '&').replace('&#8211;', '&')
            const image = (item.sizes) ? <Img src={item.sizes.src} sizes={item.sizes} /> : <img src={assetDefault} width='100%' height='100%' alt={`${cleanedTitle}`} />
            const title = <Title is='h3' f={[3]} pt={23}>{ cleanedTitle }</Title>
            const uid = (item.attribution) ? `${item.title}:${item.attribution.source}:${key}` : `${item.title}:${key}`
            return (
              <Item w={[1 / 1, 2 / 4, 1 / 4, 1 / 5]} py={30} px={50} key={uid}>
                <Box my={3}>
                  { props.link &&
                    <ItemLink to={item.link} >
                      <Shadow>{ image }</Shadow>
                      { item.title && title }
                    </ItemLink>
                  }
                  { !props.link &&
                    <div>
                      <Shadow>{ image }</Shadow>
                      { item.title && title }
                    </div>
                  }
                  { item.attribution &&
                    <ItemLink to={item.attribution.link}>
                      <Attribution f={[1]}>by <span className='highlight'>{ item.attribution.source }</span></Attribution>
                    </ItemLink>
                  }
                </Box>
              </Item>
            )
          })
        }
      </FlexWrapper>
    </Box>
  )
}

export default Listing
